<!-- eslint-disable vue/no-v-html -->
<template>
	<page-section :background-style="backgroundStyle">
		<div
			class="carousel-container"
			style="position: relative">
			<div
				class="carousel-slides"
				:style="{ transform: `translateX(-${currentSlide * 100}%)` }">
				<product-overview
					v-for="(slide, index) in slides"
					:key="index"
					class="slide"
					:title="slide.title"
					:description="slide.description"
					:banner="slide.banner"
					:image="slide.image"
					:bottom-text="slide.bottomText"
					:highlight-color="slide.highlightColor"
					:icons="slide.icons"
					:ctas="slide.ctas"
					:tab-names="tabNames"
					:current-tab="currentSlide"
					:supplement-facts="slide.supplementFacts"
					@videoUrl="videoUrl = $event"
					@supplementFacts="supplementFacts = $event"
					@setSlide="currentSlide = $event" />
			</div>
			<button
				class="btn text-gray arrow-button left-arrow-button"
				@click="prevSlide">
				<i class="fas fa-chevron-left" />
			</button>
			<button
				class="btn text-gray arrow-button right-arrow-button"
				@click="nextSlide">
				<i class="fas fa-chevron-right" />
			</button>
		</div>
		<b-modal
			id="product-overview-video"
			modal-class="media-modal"
			hide-footer
			centered
			size="lg">
			<div>
				<b-embed
					type="iframe"
					aspect="16by9"
					:src="videoUrl"
					allow="autoplay; fullscreen"
					allowfullscreen />
			</div>
		</b-modal>
		<b-modal
			id="product-overview-supplement-facts"
			ref="productOverviewSupplementFacts"
			hide-footer
			hide-header
			centered
			size="lg">
			<div
				class="d-flex justify-content-center align-items-center pointer font-weight-bold"
				style="width: 20px; height: 20px; position: absolute; top: 20px; right: 20px; border-radius: 50%; border: solid 0.08em black;"
				@click="$refs.productOverviewSupplementFacts.hide()">
				x
			</div>
			<div class="py-5 px-3">
				<div class="row">
					<div class="col-12 col-lg-5">
						<img
							v-if="supplementFacts.image"
							:src="supplementFacts.image"
							class="img-responsive"
							width="100%"
							alt="Supplement Facts">
					</div>
					<div class="col-12 col-lg-7">
						<div class="row">
							<div class="col">
								<div
									v-if="supplementFacts.content"
									v-html="supplementFacts.content" />
								<b-row class="mx-1">
									<b-col
										v-for="(badge, key) in supplementFacts.badges"
										:key="key"
										class="col-auto d-flex flex-column justify-content-start align-items-center text-center text-small"
										style="max-width: 50px">
										<img
											type="image/svg+xml"
											:src="badge.image"
											width="40px">
										{{ badge.text }}
									</b-col>
								</b-row>
							</div>
						</div>
					</div>
				</div>
			</div>
		</b-modal>
	</page-section>
</template>

<script>
import PageSection from '@/views/LandingPages2/components/PageSection.vue';
import ProductOverview from '@/views/LandingPages/components/ProductLandingPage4/ProductOverview.vue';

export default {
	name: 'AgencyCustomCarrousel',
	components: {
		ProductOverview,
		PageSection,
	},
	props: {
		slides: {
			type: Array,
			required: true,
		},
		backgroundStyle: {
			type: String,
			default: '',
		},
		tabNames: {
			type: Array,
			required: true,
			validate: (value) => value.length === 3,
		},
	},
	data() {
		return {
			currentSlide: 0,
			videoUrl: '',
			supplementFacts: {
				images: {},
				content: '',
			},
		};
	},
	methods: {
		prevSlide() {
			if (this.currentSlide === 0) {
				this.currentSlide = this.slides.length - 1;
			} else {
				this.currentSlide -= 1;
			}
		},
		nextSlide() {
			if (this.currentSlide === this.slides.length - 1) {
				this.currentSlide = 0;
			} else {
				this.currentSlide += 1;
			}
		},
	},
};
</script>

<style scoped>
.carousel-container {
	overflow: hidden;
	width: 100%;
}

.carousel-slides {
	display: flex;
	transition: transform 0.5s ease;
}

.slide {
	flex: 0 0 100%;
}

.arrow-button {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	box-shadow: 0 0 0 0;
	font-size: 1.5rem;
}

.left-arrow-button {
	left: -10px;
}

.right-arrow-button {
	right: -10px;
}
</style>
