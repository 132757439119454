<template>
	<div
		class="w-100 landing-page"
		:style="colorsStyles">
		<lazy
			min-height="200"
			render-on-idle>
			<hero
				id="hero"
				:background="heroData.background"
				:logo="heroData.logo"
				:image="heroData.image"
				:content="heroData.content"
				:ctas="heroData.ctas"
				:content-classes="'align-items-center hero-content-section'"
				:highlight-color="highlightColors.secondary"
				:background-layer="''"
				:align-buttons="['xs', 'sm', 'md'].includes(windowWidth) ? 'justify-content-center' : 'justify-content-start'" />
		</lazy>
		<lazy min-height="200">
			<summary-section
				:background-style="summaryData.background"
				:title="summaryData.headerContent"
				:description="summaryData.description"
				:icons="summaryData.icons"
				:max-cols="summaryData.cols"
				:brochure-url="summaryData.brochureUrl" />
		</lazy>
		<lazy min-height="200">
			<custom-carrousel
				:tab-names="overviewData.tabNames"
				:slides="overviewData.slides"
				:background-style="overviewData.backgroundStyle" />
		</lazy>
		<lazy
			id="buy-section"
			min-height="200">
			<buy-section
				:highlight-color="highlightColors.primary"
				:background="buySectionData.background"
				:title="buySectionData.title"
				background-height="35vh"
				rounded-button
				:product="buySectionData.searchName" />
		</lazy>
		<lazy min-height="200">
			<contact-section
				:highlight-color="'white'"
				:loading="loadingReplicatedData"
				:email="email"
				:image="contactData.image"
				:title="contactData.title"
				:content="contactData.content"
				:cta="contactData.cta"
				:container-style="contactData.containerStyle"
				@mail="openMailer" />
		</lazy>
		<lazy>
			<page-section>
				<div>
					<div
						class="mx-auto text-center">
						<div
							class="p-4 mx-auto text-medium"
							style="background-color: #f5f5f5">
							{{ disclaimer.text }}
						</div>
					</div>
				</div>
			</page-section>
		</lazy>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { PRODUCT_PAGES } from '@/settings/LandingPages';
import { SPECIAL_VALUES } from '@/settings/Replicated';
import { LandingPages } from '@/translations';
import ReplicatedSite from '@/util/ReplicatedSite';
import BuySection from '@/views/LandingPages2/components/BuySection.vue';
import ContactSection from './ContactSection';
import { RESTRICTED_BY_COUNTRY } from '@/settings/LandingPages2';
import Summary from './Summary.vue';
import Lazy from '@/components/Lazy/index.vue';
import Hero from './Hero.vue';
import CustomCarrousel from './CustomCarrousel.vue';
import PageSection from '@/views/LandingPages2/components/PageSection.vue';
import { S3_PATH } from '@/settings/Images';

export default {
	name: 'AgencyProductLandingPage4',
	messages: [LandingPages],
	components: {
		PageSection,
		Hero,
		Lazy,
		SummarySection: Summary,
		CustomCarrousel,
		BuySection,
		ContactSection,
	},
	mixins: [WindowSizes],
	props: {
		pageName: {
			type: String,
			required: true,
		},
	},
	data() {
		return { replicated: new ReplicatedSite() };
	},
	computed: {
		colorsStyles() {
			return {
				'--product-primary-color': this.highlightColors.primary,
				'--product-secondary-color': this.highlightColors.secondary ?? this.highlightColors.primary,
				'--product-tertiary-color': this.highlightColors.tertiary ?? this.highlightColors.primary,
				'--bran-primary-color': '#df713d',
				'--zlem-primary-color': '#66b2e8',
				'--byom-primary-color': '#794c99',
			};
		},
		highlightColors() {
			return PRODUCT_PAGES[this.pageName].colors;
		},
		heroData() {
			const {
				background,
				logo,
				image,
				ctas,
				content,
			} = PRODUCT_PAGES[this.pageName].hero;

			return {
				background: this.requireAsset(background),
				logo: this.requireAsset(logo),
				image: this.requireAsset(image),
				ctas,
				content: content.map((item) => ({ ...item, text: this.translate(item.text) })),
			};
		},
		summaryData() {
			const {
				title,
				description,
				brochureUrl,
				icons,
				iconCols,
				background,
			} = PRODUCT_PAGES[this.pageName].summary;

			const result = icons.map((icon) => ({
				text: this.translate(icon.text),
				file: this.requireAsset(icon.file),
			}));

			return {
				headerContent: this.translate(title),
				description: this.translate(description),
				brochureUrl,
				icons: result,
				cols: iconCols,
				background,
			};
		},
		overviewData() {
			const {
				tabNames,
				slides,
				background,
			} = PRODUCT_PAGES[this.pageName].overview;

			const result = slides.map((slide) => ({
				title: this.translate(slide.title),
				description: this.translate(slide.description),
				bottomText: this.translate(slide.bottomText),
				banner: this.requireAsset(slide.banner),
				image: this.requireAsset(slide.image),
				highlightColor: slide.highlightColor,
				icons: slide.icons.map((icon) => ({
					text: this.translate(icon.text),
					file: this.requireAsset(icon.file),
				})),
				ctas: slide.ctas.map((cta) => this.transformCta(cta)),
				supplementFacts: {
					image: this.requireAsset(slide.supplementFacts.image),
					content: this.translate(slide.supplementFacts.content),
					badges: slide.supplementFacts.badges.map((badge) => ({ image: this.requireAsset(badge.image), text: this.translate(badge.text) })),
				},
			}));

			const tabs = tabNames.map((tab) => ({
				...tab,
				title: this.translate(tab.title),
			}));

			return {
				tabNames: tabs,
				slides: result,
				backgroundStyle: `background: url(${this.requireAsset(background)})`,
			};
		},
		buySectionData() {
			const {
				background,
				searchName,
				title,
			} = PRODUCT_PAGES[this.pageName].buy;

			return {
				background: background.startsWith('#') || background.includes('gradient') ? background : this.requireAsset(background),
				searchName,
				title: this.translate(title),
			};
		},
		contactData() {
			const {
				image,
				title,
				content,
				cta,
				background,
			} = PRODUCT_PAGES[this.pageName].contact;

			const ctaData = this.transformCta(cta);
			let containerStyle = ['xs', 'sm'].includes(this.windowWidth) ? '' : 'height: 400px !important;';
			if (background) {
				containerStyle += `background: ${background};`;
			}

			return {
				containerStyle,
				image: this.requireAsset(image),
				title: this.translate(title),
				content: this.translate(content),
				cta: ctaData,
			};
		},
		disclaimer() {
			const {
				text,
			} = PRODUCT_PAGES[this.pageName].disclaimer;
			return {
				text: this.translate(text),
			};
		},
		loadingReplicatedData() {
			return !!this.replicated.data.loading;
		},
		email() {
			try {
				return this.replicated.data.response.data.response.email[0].value;
			} catch (error) {
				return '';
			}
		},
	},
	created() {
		const restricted = RESTRICTED_BY_COUNTRY[this.country] ?? RESTRICTED_BY_COUNTRY.default ?? [];
		if (restricted.includes(this.pageName)) {
			this.$router.replace({ name: 'AgencyStore' });
		}

		this.replicated.clear();

		const replicatedUsername = this.$replicated.replicatedId();
		if (replicatedUsername) {
			this.replicated.getReplicatedSiteNav(replicatedUsername).catch(() => {
				this.$replicated.siteOff();
				this.$replicated.siteReplicatedSponsorOff();
				this.$replicated.siteReplicatedPageOff();
			});
		}
	},
	methods: {
		requireAsset(directory) {
			// Allow importing assets with variable directories
			/* eslint-disable global-require */
			/* eslint-disable import/no-dynamic-require */
			// return require(`@/assets/images/themes/${process.env.VUE_APP_THEME}/landing_pages/${this.pageName}/${directory}`);
			return `${S3_PATH}/assets/landing-pages/${process.env.VUE_APP_THEME}/${this.pageName}/${directory}`;
		},
		transformCta(cta) {
			const result = {
				text: this.translate(cta.text),
			};

			if (cta.url) {
				result.url = cta.url;
			}

			if (cta.videoUrl) {
				result.videoUrl = cta.videoUrl;
			}

			if (cta.target) {
				result.target = cta.target;
			}
			return result;
		},
		openMailer() {
			if (this.email) {
				location.href = SPECIAL_VALUES.email(this.email); // eslint-disable-line no-restricted-globals
			}
		},
	},
};
</script>
<style lang="scss" scoped>
html, body {
	scroll-behavior: smooth;
}
.landing-page::v-deep {
	a {
		color: var(--product-primary-color);

		&:hover {
			color: var(--product-secondary-color);
			text-decoration: none;
		}
	}
	b, .font-weight-bolder {
		font-family: MontserratExtraBold, Montserrat, sans-serif !important;
		font-weight: 700 !important;
	}
	li>span, p {
		color: #666;
		line-height: 25px;
		font-weight: 500;
	}
	.border-2 {
		border-width: 2px !important;
	}
	.highlight-block {
		background-color: var(--product-primary-color);
		border-radius: 10px !important;
		line-height: 1em !important;
		font-family: Montserrat, sans-serif !important;
		font-weight: 700;
		padding: 1.5rem 3rem;
		color: white !important;

		& > p {
			color: white !important;
		}
	}
	.bran, {
		&-primary-color {
			color: var(--bran-primary-color) !important;
		}
	}
	.zlem, {
		&-primary-color {
			color: var(--zlem-primary-color) !important;
		}
	}
	.byom, {
		&-primary-color {
			color: var(--byom-primary-color) !important;
		}
	}
	.product, {
		&-primary-color {
			color: var(--product-primary-color) !important;
		}
		&-secondary-color {
			color: var(--product-secondary-color) !important;
		}
		&-tertiary-color {
			color: var(--product-tertiary-color) !important;
		}
	}
	.bg-product {
		&-primary {
			background-color: var(--product-primary-color) !important;
		}
		&-secondary {
			background-color: var(--product-secondary-color) !important;
		}
	}
	.quote {
		box-sizing: border-box;
		font-style: italic;
		padding: 0;
		text-align: center;

		&::before, &::after {
			color: var(--product-primary-color);
			display: block;
			font-family: MontserratExtraBold, Montserrat, sans-serif !important;
			font-size: 20px;
		}
		&::before {
			content: "“\A";
			white-space: pre;
		}
		&::after {
			content: "\A”";
			line-height: 0.75em;
			white-space: pre;
		}
	}
	.secondary-header {
		font-family: Montserrat, sans-serif !important;
		font-size: 2rem;
		font-weight: 500;
		letter-spacing: 4px;
		text-transform: uppercase;
	}
	.section-header {
		font-size: 2.5rem;
		font-family: MontserratExtraBold, Montserrat, sans-serif !important;
		font-weight: 700 !important;
	}
	.hero-content-section img {
		width: 30%;
	}

	.logo-container img {
		transform: translateX(150px);
	}

	@media only screen and (max-width: 991px) {
		.hero-content-section .content-logo-container {
			display: flex;
			justify-content: center;
		}

		.logo-container img {
			width: 70%;
			transform: none;
		}

		.hero-content-section img {
			width: 25%;
		}
	}
	@media only screen and (max-width: 767px) {
		h1.section-header {
			font-size: 2.5rem !important;
		}
		h1 + .secondary-header {
			font-size: 1.75rem !important;
		}
		.logo-container img {
			width: 100%;
		}
		.hero-content-section img {
			width: 40%;
		}
	}
	.source {
		color: var(--product-secondary-color) !important;
		font-size: 0.875em;
		font-style: italic;
	}
}
</style>
