<!-- eslint-disable vue/no-v-html -->
<template>
	<page-section :background-style="backgroundStyle ? backgroundStyle : ''">
		<div>
			<div
				v-if="title.length"
				v-html="title" />
			<div
				v-if="description.length"
				v-html="description" />
			<div
				v-for="(iconRow, rowIndex) in iconRows"
				:key="`iconRow-${rowIndex}`"
				:class="rowIndex === iconRows.length - 1 ? '' : 'mb-5'"
				class="row justify-content-center">
				<div
					v-for="(icon, iconIndex) in iconRow"
					:key="`iconCol-${iconIndex}`"
					class="col-auto d-flex flex-column align-items-center mx-4">
					<object
						v-if="icon.file.endsWith('.svg')"
						type="image/svg+xml"
						:data="icon.file"
						height="50%">
						Your browser does not support SVG.
					</object>
					<img
						v-else
						class="img-fluid"
						style="max-height: 100px; !important"
						:src="icon.file">
					<span
						class="mt-3 text-center text-white"
						style="max-width: 190px;">
						<div
							class="d-inline"
							v-html="icon.text" />
					</span>
				</div>
			</div>
			<div
				v-if="brochureUrl"
				class="mt-4 pt-2 d-flex justify-content-center">
				<a
					:href="brochureUrl"
					target="_blank"
					class="btn btn-light"
					style="font-weight: bold; background-color: transparent; color: white">
					{{ translate('download_brochure') }}
				</a>
			</div>
		</div>
	</page-section>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import PageSection from '../PageSection';
import { LandingPages } from '@/translations';

export default {
	name: 'AgencyIconsSection',
	messages: [LandingPages],
	components: {
		PageSection,
	},
	mixins: [WindowSizes],
	props: {
		backgroundStyle: {
			type: String,
			default: '',
		},
		title: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
		icons: {
			type: Array,
			required: true,
		},
		maxCols: {
			type: [String, Number],
			required: true,
		},
		brochureUrl: {
			type: String,
			default: '',
		},
	},
	computed: {
		iconRows() {
			const { windowWidth } = this;
			const colsPerWindowWidth = {
				xs: 2,
				sm: 3,
				md: 3,
				lg: 3,
				xl: this.maxCols,
			};
			const rows = [];

			this.icons.forEach((icon) => {
				if (rows.length === 0 || rows[rows.length - 1].length >= colsPerWindowWidth[windowWidth]) {
					rows.push([]);
				}
				rows[rows.length - 1].push(icon);
			});
			return rows;
		},
	},
};
</script>
