<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="w-100">
		<div
			:class="windowWidth === 'xs' ? 'mt-5' : ''"
			class="h-100"
			:style="backgroundStyle">
			<b-row
				:class="{
					[backgroundLayer]: ['xs', 'sm', 'md'].includes(windowWidth),
					'd-flex align-items-center justify-content-center': ['xs', 'sm', 'md'].includes(windowWidth),
				}"
				:style="backgroundHeight">
				<b-col
					:cols="['xs', 'sm', 'md'].includes(windowWidth) ? '10' : '12'"
					:lg="logo ? '6' : '5'"
					class="d-flex align-items-center px-5 logo-container"
					:class="{
						'justify-content-center': ['xs', 'sm', 'md'].includes(windowWidth),
					}">
					<img
						v-if="!!logo"
						:src="logo"
						class="embed-responsive"
						alt="logo">
				</b-col>
				<b-col
					cols="12"
					:lg="logo ? '6' : '7'"
					class="d-flex"
					:class="contentClasses">
					<b-row
						class="px-4"
						:class="{
							'd-flex align-items-center justify-content-center': ['xs', 'sm', 'md'].includes(windowWidth),
						}">
						<b-col
							v-if="!!image"
							cols="10 content-logo-container">
							<img
								:src="image"
								class="embed-responsive"
								alt="image">
						</b-col>
						<b-col
							v-for="(item, index) in content"
							:key="index"
							cols="12">
							<div
								:class="['xs', 'sm', 'md'].includes(windowWidth) ? itemClass(item).small : itemClass(item).big"
								:style="`${item.highlight ? makeHighlightStyle('color') : ''} ${['xs', 'sm', 'md'].includes(windowWidth) ? item.style.small : item.style.big}`"
								v-html="item.text" />
						</b-col>
						<b-col cols="12">
							<slot name="flavors" />
						</b-col>
						<b-col
							cols="12"
							:class="['xs', 'sm', 'md'].includes(windowWidth) ? 'mb-3' : ''">
							<div
								class="d-flex flex-nowrap"
								:class="alignButtons"
								style="gap: 1rem">
								<button
									v-for="(cta, index) in ctas"
									:key="index"
									class="learn-more-btn btn btn-lg rounded-0 border-2 mb-1"
									:class="{
										[cta.class]: true,
										'px-5': !['xs', 'sm', 'md'].includes(windowWidth)
									}"
									:style="`${cta.type === 'filled' ? 'background-color' : 'color'}: ${cta.color}; border-color: ${cta.color}`"
									@click="handleClick(cta)">
									<span
										class="learn-more font-weight-bolder letter-spacing m-0"
										style="font-size: 1rem">
										{{ translate(cta.text) }}
									</span>
								</button>
							</div>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import Color from '../../mixins/Color';
import Scroll from '../../mixins/Scroll';

export default {
	name: 'AgencyLandingPageHero',
	mixins: [Color, Scroll, WindowSizes],
	props: {
		background: {
			type: String,
			required: true,
		},
		title: {
			type: Object,
			default: () => ({}),
		},
		subtitle: {
			type: Object,
			default: () => ({}),
		},
		description: {
			type: String,
			default: '',
		},
		ctas: {
			type: Array,
			default: () => [],
		},
		logo: {
			type: String,
			default: '',
		},
		image: {
			type: String,
			default: '',
		},
		backgroundLayer: {
			type: String,
			default: 'gray-layer',
		},
		contentClasses: {
			type: String,
			required: true,
		},
		content: {
			type: Array,
			default: () => [],
		},
		alignButtons: {
			type: String,
			default: 'justify-content-start',
		},
	},
	computed: {
		backgroundStyle() {
			const styles = [
				`background-image: linear-gradient(rgba(90,90,90,0.5) 20px, rgba(1,1,1,0) 95px, transparent), url('${this.background}');`,
				'background-size: cover;',
				'background-position: 50% 50%;',
				'background-repeat: none;',
				this.backgroundHeight,
			];

			if (this.$replicated.replicatedSponsor()) {
				styles.push(this.windowWidth === 'xs' ? 'margin-top: 55px !important;' : 'margin-top: 35px !important;');
			}

			return styles.join(' ');
		},
		backgroundHeight() {
			if (this.$replicated.replicatedSponsor()) {
				// eslint-disable-next-line no-nested-ternary
				return this.windowWidth === 'xs' ? 'min-height: 70vh;' : (['lg', 'xl'].includes(this.windowWidth) ? 'min-height: 100vh;' : 'min-height: 60vh;');
			}
			return this.windowWidth === 'xs' ? 'min-height: 70vh;' : 'min-height: 100vh;';
		},
	},
	methods: {
		handleClick(cta) {
			if (cta.showModal) {
				this.$root.$emit('bv::show::modal', cta.showModal);
			} else if (cta.scrollTo) {
				this.scrollTo(cta.scrollTo);
			}
		},
		itemClass(item) {
			return item.class[this.language] ?? item.class.default;
		},
	},
};
</script>
<style scoped>
.title {
	font-family: 'MontserratExtraBold', sans-serif;
	font-size: 4vw;
	font-weight: bolder;
	line-height: 0.5;
}

.subtitle {
	font-family: 'TheChatalestickSans', sans-serif;
	font-size: 15vw;
	text-transform: uppercase;
	line-height: 1;
}

@media screen and (max-width: 768px) {
	.smaller-subtitle {
		font-size: 17vw !important;
		line-height: 1.2;
	}
}

@media screen and (min-width: 768px) {
	.smaller-subtitle {
		font-size: 9vw !important;
		line-height: 1.2;
	}
}

.letter-spacing {
	letter-spacing: 0.1vw;
}

.lg-letter-spacing {
	letter-spacing: 1.2vw;
}

.border-2 {
	border-width: 2px !important;
}

.dark-gray {
	color: #535353;
}

.gray-layer {
	background-color: rgba(10, 10, 10, 0.3);
}
.learn-more-btn:focus {
	box-shadow: none;
}
</style>
